import { useEffect } from "react";

import {
  ERROR_CODE_QUERY_PARAM,
  LS_KC_TOKEN_EXP,
  isLogoutInProgressAtom,
  useAuthLogin,
  useIsAuthenticated,
} from "@keepeek/commons";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import { useRecoilState, useRecoilValue } from "recoil";

import { isKeycloakConnectWithGuestAvailable } from "../../../components/landingPage/utils";
import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import logger from "../../../lib/logger-utils";
import { AutoGuestLogin, Global } from "../../../models/configuration/global";
import { useConfiguration } from "../../config/hooks";
import { useBackdoors } from "../../config/hooks/useBackdoors";
import useFrontEdit from "../../frontEdit/hooks/useFrontEdit";
import { autoGuestAuthAtom } from "../atoms/autoGuestAuth";
import { useAuthProps } from "./useAuthProps";
import { useAuthRedirectUri } from "./useAuthRedirectUri";
import { useKeycloakConfig } from "./useKeycloakConfig";

export const useAutoGuestAuth = () => {
  const { loading: loadingKCConfig } = useKeycloakConfig();
  const { redirectUri } = useAuthRedirectUri();
  const { guestLogin, guestLoginUrl } = useAuthLogin({
    ...useAuthProps(),
    redirectUri: `${location.origin}${redirectUri}`,
  });

  const isLogoutInProgressState = useRecoilValue(isLogoutInProgressAtom);
  const [inProgress, setInProgress] = useRecoilState(autoGuestAuthAtom);
  const { loading: loadingAuth } = useIsAuthenticated();
  const globalConfiguration = useConfiguration<Global, true>(
    CONFIGURATION_SECTION_PATH.GLOBAL,
    true,
  );
  const { frontEdit } = useFrontEdit();

  const { query } = useRouter();
  const { iAmAGuest, stayOnLanding } = useBackdoors();

  const available = isKeycloakConnectWithGuestAvailable(
    !!globalConfiguration ? globalConfiguration?.keycloakConfiguration : undefined,
  );

  const autoWithParam =
    globalConfiguration?.keycloakConfiguration?.autoGuestLogin ===
      AutoGuestLogin.AutoGuestLoginWithParam && iAmAGuest;

  const autoWithStay =
    globalConfiguration?.keycloakConfiguration?.autoGuestLogin === AutoGuestLogin.AutoGuestLogin &&
    !stayOnLanding;

  // TODO: Remove this "inError" case, as it is no longer needed.
  // The new error case is `${FIRST_LOGIN_TYPE_QUERY_PARAM}=${FIRST_LOGIN_TYPE.ERROR}` now, and the visitor can be logged in with this new query param.
  // We still support it because an override in Guerlain is using it, and it should be updated to use a different method.
  const inError = !!query[ERROR_CODE_QUERY_PARAM]; // Check if there is an error code in the query parameters

  const loading =
    loadingKCConfig ||
    loadingAuth ||
    globalConfiguration?.useConfigurationLoadingState ||
    inProgress;

  const shouldAutoGuestLog =
    !frontEdit &&
    !inError &&
    !loading &&
    available &&
    (autoWithParam || autoWithStay) &&
    !!guestLoginUrl &&
    !isLogoutInProgressState;

  useEffect(() => {
    const expiration = (): boolean => {
      const getExpiration = localStorage.getItem(LS_KC_TOKEN_EXP);
      const expiration = isNaN(Number(getExpiration)) ? undefined : Number(getExpiration) * 1000;
      const isStillValid = expiration ? dayjs().isBefore(dayjs(expiration)) : false;
      return isStillValid;
    };

    if (shouldAutoGuestLog && !inProgress && !expiration()) {
      logger.debug("useAutoGuestAuth - try to auto log guest");
      setInProgress(true);
      guestLogin();
    }
  }, [setInProgress, shouldAutoGuestLog, inProgress, guestLogin]);

  return { loading, shouldAutoGuestLog };
};
